import React from 'react';

import { Layout } from '../components/layout';
import { Title, Paragraph } from '../components/copy';
import { MenuLink } from '../components/menu';

const Tarieven = ({ location }) => (
  <Layout location={location}>
    <div className="mb-4">
      <MenuLink to="/">{'< Terug naar homepagina'}</MenuLink>
    </div>

    <Title>Tarieven</Title>

    <Paragraph>
      Tarieven per 1 januari 2024 (alle bedragen zijn inclusief BTW)
    </Paragraph>

    <Title>Bewindvoering</Title>

    <section className="mb-10">
      <header>
        <Title small>Eenpersoonsbewind</Title>
      </header>
      <table className="w-full text-left">
        <tbody>
          <tr className="border-b h-10">
            <th className="font-normal">Standaard</th>
            <td className="w-24 text-right">€ 133,10</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Problematische schulden</th>
            <td className="w-24 text-right">€ 172,12</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Opstartkosten</th>
            <td className="w-24 text-right">€ 751,41</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Beëindiging bewind</th>
            <td className="w-24 text-right">€ 281,93</td>
          </tr>
        </tbody>
      </table>
    </section>

    <section className="mb-4">
      <header>
        <Title small>Tweepersoonsbewind</Title>
      </header>
      <table className="w-full text-left">
        <tbody>
          <tr className="border-b h-10">
            <th className="font-normal">Standaard</th>
            <td className="w-24 text-right">€ 159,52</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Problematische schulden een persoon</th>
            <td className="w-24 text-right">€ 183,11</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">
              Problematische schulden beide personen
            </th>
            <td className="w-24 text-right">€ 206,51</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Opstartkosten</th>
            <td className="w-24 text-right">€ 901,45</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Beëindiging bewind</th>
            <td className="w-24 text-right">€ 338,80</td>
          </tr>
        </tbody>
      </table>
    </section>

    <div className="italic mb-10">
      Is de bewindvoerder ook benoemd tot mentor, zie dan de tarieven voor
      curatele.
    </div>

    <Title>Mentorschap</Title>

    <section className="mb-10">
      <header>
        <Title small>Eenpersoons mentorschap</Title>
      </header>
      <table className="w-full text-left">
        <tbody>
          <tr className="border-b h-10">
            <th className="font-normal">Standaard</th>
            <td className="w-24 text-right">€ 133,10</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">
              Psychische problemen 18-23 jaar één persoon
            </th>
            <td className="w-24 text-right">€ 172,12</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Opstartkosten</th>
            <td className="w-24 text-right">€ 751,41</td>
          </tr>
        </tbody>
      </table>
    </section>

    <section className="mb-4">
      <header>
        <Title small>Tweepersoons mentorschap</Title>
      </header>
      <table className="w-full text-left">
        <tbody>
          <tr className="border-b h-10">
            <th className="font-normal">Standaard</th>
            <td className="w-24 text-right">€ 239,48</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">
              Psychische problemen 18-23 jaar één persoon
            </th>
            <td className="w-24 text-right">€ 274,47</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">
              Psychische problemen 18-23 jaar beide personen
            </th>
            <td className="w-24 text-right">€ 309,86</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Opstartkosten</th>
            <td className="w-24 text-right">€ 1351,57</td>
          </tr>
        </tbody>
      </table>
    </section>

    <div className="italic mb-10">
      Is de bewindvoerder ook benoemd tot mentor, zie dan de tarieven voor
      curatele.
    </div>

    <Title>Curatele</Title>

    <section className="mb-10">
      <header>
        <Title>Eenpersoons curatele</Title>
      </header>
      <table className="w-full text-left">
        <tbody>
          <tr className="border-b h-10">
            <th className="font-normal">Standaard</th>
            <td className="w-24 text-right">€ 239,48</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Problematische schulden</th>
            <td className="w-24 text-right">€ 274,47</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Psychische problemen 18-23 jaar</th>
            <td className="w-24 text-right">€ 274,47</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">
              Problematische schulden + Psychische problemen 18-23 jaar
            </th>
            <td className="w-24 text-right">€ 309,86</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Opstartkosten</th>
            <td className="w-24 text-right">€ 1351,57</td>
          </tr>
        </tbody>
      </table>
    </section>

    <section className="mb-10">
      <header>
        <Title>Tweepersoons curatele</Title>
      </header>
      <table className="w-full text-left">
        <tbody>
          <tr className="border-b h-10">
            <th className="font-normal">Standaard</th>
            <td className="w-24 text-right">€ 345,76</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Problematische schulden één persoon</th>
            <td className="w-24 text-right">€ 365,42</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">
              Problematische schulden beide personen
            </th>
            <td className="w-24 text-right">€ 384,98</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">
              Psychische problemen 18-23 jaar één persoon
            </th>
            <td className="w-24 text-right">€ 377,12</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">
              Psychische problemen 18-23 jaar beide personen
            </th>
            <td className="w-24 text-right">€ 408,48</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Opstartkosten</th>
            <td className="w-24 text-right">€ 1952,94</td>
          </tr>
        </tbody>
      </table>
    </section>

    <section className="mb-10">
      <header>
        <Title>Budgetbeheer</Title>
      </header>
      <table className="w-full text-left">
        <tbody>
          <tr className="border-b h-10">
            <th className="font-normal">Tarief 1 persoon</th>
            <td className="w-24 text-right">€ 90,-</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Opstartkosten 1 persoon</th>
            <td className="w-24 text-right">€ 300,-</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Tarief 2 personen</th>
            <td className="w-24 text-right">€ 115,-</td>
          </tr>
          <tr className="border-b h-10">
            <th className="font-normal">Opstartkosten 2 personen</th>
            <td className="w-24 text-right">€ 350,-</td>
          </tr>
        </tbody>
      </table>
    </section>
  </Layout>
);

export default Tarieven;
